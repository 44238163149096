import { useState } from "react";

export const useCalculationResultChange = () =>
{
    const [consumption, setConsumption] = useState(null);
    const [commodity, setCommodity] = useState(null);
    const [consumptionHighTariff, setConsumptionHighTariff] = useState(null);
    const [zipCode, setZipCode] = useState(null);

    const onResultChange = ({
        consumption,
        commodity,
        consumptionLowTariff,
        consumptionHighTariff,
        postCode
    }) =>
    {
        setCommodity(commodity);
        setConsumption(consumption);
        setZipCode(postCode);

        if (consumptionLowTariff)
        {
            setConsumption(consumptionLowTariff);
        }
        if (consumptionHighTariff)
        {
            setConsumptionHighTariff(consumptionHighTariff);
        }
    }

    return {
        onResultChange,
        consumption,
        commodity,
        consumptionHighTariff,
        zipCode
    }
}